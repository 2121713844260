import React from "react";
import { graphql, PageProps } from "gatsby";

import { ContentBlocks } from "@gregorshear/gatsby-theme-taproot/src/components/ContentBlocks";
import hero from "../assets/images/hero.png";

export const query = graphql`
  query {
    contentfulSite {
      landingPage {
        blocks {
          heading
          id
          blockType
          body {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        title
        slug
        subpages {
          id
          title
          slug
        }
      }
    }
  }
`;

const HomeIndex = (
  props: PageProps<{ contentfulSite: GatsbyTypes.ContentfulPage }>
) => {
  const {
    data: {
      contentfulSite: { landingPage: page },
    },
  } = props;

  return (
    <>
      {/* <img src={hero} alt="hero" className="rounded-lg shadow-lg" /> */}
      <ContentBlocks blocks={page.blocks} />
    </>
  );
};
export default HomeIndex;
